import { SETVOLUNTEERTAGSDROPDOWNLOADING } from "redux/Types/common.types";
import { SETVOLUNTEERTAGSDROPDOWNDATA } from "redux/Types/common.types";
import { SETTAGSDROPDOWNLOADING } from "redux/Types/common.types";
import { SETTAGSDROPDOWNDATA } from "redux/Types/common.types";

export const setTagsDropdownLoading = (value) => {
  return {
    type: SETTAGSDROPDOWNLOADING,
    value,
  };
};

export const setTagsDropdownData = (data) => {
  return {
    type: SETTAGSDROPDOWNDATA,
    data,
  };
};

export const setVolunteerTagsDropdownLoading = (value) => {
  return {
    type: SETVOLUNTEERTAGSDROPDOWNLOADING,
    value,
  };
};

export const setVolunteerTagsDropdownData = (data) => {
  return {
    type: SETVOLUNTEERTAGSDROPDOWNDATA,
    data,
  };
};
