export const categoryList = [
  {
    value: "A",
    label: "Arts, Culture and Humanities",
  },
  {
    value: "B",
    label: "Educational Institutions and Related Activities",
  },
  {
    value: "C",
    label: "Environmental Quality, Protection and Beautification",
  },
  {
    value: "D",
    label: "Animal-Related",
  },
  {
    value: "E",
    label: "Health – General and Rehabilitative,",
  },
  {
    value: "F",
    label: "Mental, Health, Crisis Intervention",
  },
  {
    value: "G",
    label: "Diseases, Disorders, Medical Disciplines",
  },
  {
    value: "H",
    label: "Medical, Research",
  },
  {
    value: "I",
    label: "Crime, Legal-Related",
  },
  {
    value: "J",
    label: "Employment, Job-Related",
  },
  {
    value: "K",
    label: "Food, Agriculture and Nutrition",
  },
  {
    value: "L",
    label: "Housing, Shelter",
  },
  {
    value: "M",
    label: "Public, Safety, Disaster Preparedness and Relief",
  },
  {
    value: "N",
    label: "Recreation, Sports, Leisure, Athletics",
  },
  {
    value: "O",
    label: "Youth, Development",
  },
  {
    value: "P",
    label: "Human, Services – Multipurpose and Other",
  },
  {
    value: "Q",
    label: "International, Foreign Affairs and National Security",
  },
  {
    value: "R",
    label: "Civil, Rights, Social Action, Advocacy",
  },
  {
    value: "S",
    label: "Community, Improvement, Capacity Building",
  },
  {
    value: "T",
    label: "Philanthropy, Voluntarism and Grantmaking Foundations",
  },
  {
    value: "U",
    label: "Science, and Technology Research Institutes, Services",
  },
  {
    value: "V",
    label: "Social, Science Research Institutes, Services",
  },
  {
    value: "W",
    label: "Public, Society Benefit – Multipurpose and Other",
  },
  {
    value: "X",
    label: "Religion-Related, Spiritual Development",
  },
  {
    value: "Y",
    label: "Mutual/Membership, Benefit Organizations, Other",
  },
  {
    value: "Z",
    label: "Unknown",
  },
];
