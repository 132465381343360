export const SETEVENTLOADING = "SETEVENTLOADING";

export const SETEVENTLISTANDTOTAL = "SETEVENTLISTANDTOTAL";

export const SETEVENTDETAILS = "SETEVENTDETAILS";

export const SETEVENTPARTICIPANTDATA = "SETEVENTPARTICIPANTDATA";

export const SETVOLUNTEEREVENTPARTICIPANTDATA =
  "SETVOLUNTEEREVENTPARTICIPANTDATA";

export const SETCANCELSCHEDULELOADING = "SETCANCELSCHEDULELOADING";

export const SETHOMEPAGEEVENT = "SETHOMEPAGEEVENT";

export const SETORGANIZATIONEVENT = "SETORGANIZATIONEVENT";
