import { SET_DONATION_LIST_AND_TOTAL } from "redux/Types/donation.types";
import { SET_DONATION_LOADING } from "redux/Types/donation.types";

const INITIAL_STATE = {
  loading: false,
  donationList: [],
  donationListTotal: 0,
};

/*eslint-disable*/
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DONATION_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_DONATION_LIST_AND_TOTAL:
      return {
        ...state,
        donationList: action.data,
        donationListTotal: action.total,
      };
    default:
      return state;
  }
};

export default reducer;
