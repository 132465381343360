import { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import awsconfig from "aws-exports";
import {
  ISAUTHENTICATE,
  SETLOCATIONCLIENT,
  SETUSERDETAILS,
} from "redux/Types/auth.types";

export const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  disableOffline: true,
});

export const client2 = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: awsconfig.aws_appsync_apiKey,
  },
  disableOffline: true,
});

const INITIAL_STATE = {
  isAuthenticate: false,
  user: null,
  gqlClient: client,
  visitorGQLClient: client2,
  locationClient: null,
};

/*eslint-disable*/
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ISAUTHENTICATE:
      return {
        ...state,
        isAuthenticate: action.payload,
      };
    case SETUSERDETAILS: {
      return {
        ...state,
        user: action.details,
      };
    }
    case SETLOCATIONCLIENT: {
      return {
        ...state,
        locationClient: action.client,
      };
    }
    default:
      return state;
  }
};

export default reducer;
