import { SETEVENTLISTANDTOTAL } from "redux/Types/event.types";
import { SETVOLUNTEEREVENTPARTICIPANTDATA } from "redux/Types/event.types";
import { SETHOMEPAGEEVENT } from "redux/Types/event.types";
import { SETORGANIZATIONEVENT } from "redux/Types/event.types";
import { SETCANCELSCHEDULELOADING } from "redux/Types/event.types";
import { SETEVENTPARTICIPANTDATA } from "redux/Types/event.types";
import { SETEVENTDETAILS } from "redux/Types/event.types";
import { SETEVENTLOADING } from "redux/Types/event.types";

export const setEventLoading = (value) => {
  return { type: SETEVENTLOADING, value };
};

export const setCancelScheduleLoading = (value) => {
  return { type: SETCANCELSCHEDULELOADING, value };
};

export const setEventListAndTotal = (list, total) => {
  return { type: SETEVENTLISTANDTOTAL, list, total };
};

export const setEventDetails = (details) => {
  return { type: SETEVENTDETAILS, details };
};

export const setEventParicipantData = (list, total) => {
  return { type: SETEVENTPARTICIPANTDATA, list, total };
};

export const setVolunteerEventParicipantData = (list, total) => {
  return { type: SETVOLUNTEEREVENTPARTICIPANTDATA, list, total };
};

export const setHomePageEvent = (data) => {
  return {
    type: SETHOMEPAGEEVENT,
    data,
  };
};

export const setOrganizationEvent = (data) => {
  return {
    type: SETORGANIZATIONEVENT,
    data,
  };
};
