import { SETVOLUNTEEREVENTPARTICIPANTDATA } from "redux/Types/event.types";
import { SETORGANIZATIONEVENT } from "redux/Types/event.types";
import { SETEVENTPARTICIPANTDATA } from "redux/Types/event.types";
import {
  SETCANCELSCHEDULELOADING,
  SETEVENTLOADING,
  SETEVENTLISTANDTOTAL,
  SETEVENTDETAILS,
  SETHOMEPAGEEVENT,
} from "redux/Types/event.types";
const INITIAL_STATE = {
  loading: false,
  cancelLoading: false,
  eventList: [],
  eventListTotal: 0,
  eventDetails: {},
  eventParticipantList: [],
  eventParticipantListTotal: 0,

  volunteerEventParticpantList: [],
  volunteerEventParticpantListTotal: 0,

  homePageEvent: [],

  organizationEvent: [],
};

/*eslint-disable*/
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETEVENTLOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SETHOMEPAGEEVENT:
      return {
        ...state,
        homePageEvent: action.data,
      };
    case SETORGANIZATIONEVENT:
      return {
        ...state,
        organizationEvent: action.data,
      };
    case SETCANCELSCHEDULELOADING:
      return {
        ...state,
        cancelLoading: action.value,
      };
    case SETEVENTLISTANDTOTAL:
      return {
        ...state,
        eventList: action.list,
        eventListTotal: action.total,
      };
    case SETVOLUNTEEREVENTPARTICIPANTDATA:
      return {
        ...state,
        volunteerEventParticpantList: action.list,
        volunteerEventParticpantListTotal: action.total,
      };
    case SETEVENTPARTICIPANTDATA:
      return {
        ...state,
        eventParticipantList: action.list,
        eventParticipantListTotal: action.total,
      };
    case SETEVENTDETAILS:
      return {
        ...state,
        eventDetails: action.details,
      };
    default:
      return state;
  }
};

export default reducer;
