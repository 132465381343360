export const SETORGANIZATIONLISTANDTOTAL = "SETORGANIZATIONLISTANDTOTAL";

export const SETORGANIZATIONLOADING = "SETORGANIZATIONLOADING";

export const SETORGANIZATIONDETAILS = "SETORGANIZATIONDETAILS";

export const SETOWNERORGANIZATIONLISTANDTOTAL =
  "SETOWNERORGANIZATIONLISTANDTOTAL";

export const SETOWNERORGANIZATIONDETAILS = "SETOWNERORGANIZATIONDETAILS";

export const SETPROFILEDETAILS = "SETPROFILEDETAILS";

export const SETMAINLOADING = "SETMAINLOADING";

export const SETCITYLOADING = "SETCITYLOADING";

export const SETCITYDROPDOWNDATA = "SETCITYDROPDOWNDATA";

export const SETLOCATIONLOADING = "SETLOCATIONLOADING";

export const SETAUTOLOCATIONDATA = "SETAUTOLOCATIONDATA";

export const SETCATEGORYLOADING = "SETCATEGORYLOADING";

export const SETCATEGORYDROPDOWNDATA = "SETCATEGORYDROPDOWNDATA";
