export const getOrganizationListGraphQL = `
query searchOrganizations(
  $filter: SearchableOrganizationFilterInput,
	$sort: [SearchableOrganizationSortInput],
	$limit: Int,
  $from: Int
  ){
  searchOrganizations(from: $from, limit: $limit,sort:$sort,filter:$filter) {
    items {
      id
      name
      category
      address1
      description
    }
    total
  }
  }`;

export const getOwnerOrganizationListGraphQL = `
query searchOrganizations(
  $filter: SearchableOrganizationFilterInput,
	$sort: [SearchableOrganizationSortInput],
	$limit: Int,		
  $from: Int
  ){
  searchOrganizations(from: $from, limit: $limit,sort:$sort,filter:$filter) {
    items {
      id
      name           
      phone
      owner
    }
    total
  }
  }`;

export const getEventTagsListGraphQL = `
query searchEventTags(
  $filter: SearchableEventTagFilterInput,
	$sort: [SearchableEventTagSortInput],
	$limit: Int,
  $from: Int
 ) {
  searchEventTags(from: $from, limit: $limit,sort:$sort,filter:$filter) {
    items {      
      id
      value
    }
    total
  }
  }`;

export const getVolunteerTagsListGraphQL = `
query searchVolunteerTags(
  $filter: SearchableVolunteerTagFilterInput,
	$sort: [SearchableVolunteerTagSortInput],
	$limit: Int,
  $from: Int
 ) {
  searchVolunteerTags(from: $from, limit: $limit,sort:$sort,filter:$filter) {
    items {      
      id
      value
    }
    total
  }
  }`;

export const getEventDetailsGraphQL = `
query getEvent(
  $id: ID!
  ){
    getEvent(id:$id) {
      id
      title
      address1
      address2
      city
      country
      createdAt
      description
      image {
        bucket
        key
        region
      }
      images {
        bucket
        key
        region
      }
      location {
        lat
        lon
      }
      name
      organization {
        id
        name
      }
      schedule {
        items {
          EndTime
          StartTime
          RecurrenceException
          RecurrenceID
          RecurrenceRule
          maxVolunteers
          isCancelled
          currentVolunteerCount
          OwnerId
          Id
          id
          eventScheduleId
          volunteerSchedule {
            items {
              id
              scheduleVolunteerScheduleId
              volunteerVolunteerScheduleId
            }
          }
        }
      }
      organizationEventId
      phone
      state
      tags
      updatedAt
      zipcode
  }
  }`;

export const checkVolunteerProfileDetailsGraphQL = `
query getVolunteer(
  $id: ID!
 ) {
  getVolunteer(id:$id) {    
      id
      address1
      address2
      city
      contactEmail
      country
      createdAt
      name
      phone
      state
      tags
      updatedAt
      zipcode
      image {
        bucket
        key
        region
      }
  }
  }`;

export const createVolunteerProfileGraphQL = `
mutation createVolunteer($input: CreateVolunteerInput!){
  createVolunteer(input:$input) {    
      id
      address1
      address2
      city
      contactEmail
      country
      createdAt
      name
      phone
      state
      tags
      updatedAt
      zipcode    
      image {
        bucket
        key
        region
      }
    }
  }`;

export const createEmailGraphQL = `
mutation createEmail($input: CreateEmailInput!){
  createEmail(input:$input) {    
    body
    from
    id
    subject
    to    
    }
  }`;

export const editVolunteerProfileGraphQL = `
mutation updateVolunteer($input: UpdateVolunteerInput!){
  updateVolunteer(input:$input) {    
      id
      address1
      address2
      city
      contactEmail
      country
      createdAt
      name
      phone
      state
      tags
      updatedAt
      zipcode    
      image {
        bucket
        key
        region
      }
    }
  }`;

export const checkOrganizationByOwnerGraphQL = /* GraphQL */ `
  query organizationByOwner($owner: String!) {
    organizationByOwner(owner: $owner) {
      items {
        id
        category
        email
        name
        owner
        mission
        description
        website
        email
        address1
        address2
        city
        country
        state
        zip
        phone
        facebook
        instagram
        twitter
        linkedin
        donations
        stripeAccount
        image {
          bucket
          key
          region
        }
        location {
          lat
          lon
        }
      }
    }
  }
`;

export const getCityDropdownDataGraphQL = `
query searchLocationData(
  $filter: SearchableLocationDataFilterInput,
	$sort: [SearchableLocationDataSortInput],
	$limit: Int,
  $from: Int
 ) {
  searchLocationData(from: $from, limit: $limit,sort:$sort,filter:$filter) {
    items {      
      city
    }
    total
  }
  }`;

export const createNewEventGraphQL = `
  mutation createEvent($input: CreateEventInput!){
    createEvent(input:$input) {    
        id
        title
      }
    }`;

export const updateEventGraphQL = `
  mutation updateEvent($input: UpdateEventInput!){
    updateEvent(input:$input) {    
      address1
      address2
      city
      country
      createdAt
      description
      id
      images {
        bucket
        key
        region
      }
      image {
        bucket
        key
        region
      }
      location {
        lat
        lon
      }
      name
      organization {
        id
        name
      }
      organizationEventId
      phone
      state
      tags
      title
      updatedAt
      zipcode
      }
    }`;

export const getEventListGraphQL = `
    query searchEventsByLocation(
      $filter: SearchableEventByLocationFilterInput,
      $distance: SearchableDistanceInput,
      $sort: [SearchableEventByLocationSortInput],
      $limit: Int,
      $from: Int
     ) {
      searchEventsByLocation(from: $from, limit: $limit,sort:$sort,filter:$filter,distance:$distance) {
        total
    items {
      organization {
        id
        name
      }
      address1
      address2
      city
      country
      createdAt
      description
      id
      image {
        bucket
        key
        region
      }
      images {
        bucket
        key
        region
      }
      location {
        lat
        lon
      }
      name
      organizationEventId
      phone
      state
      tags
      title
      updatedAt
      zipcode
      schedule(sortDirection: DESC) {
        items {
          Id
          id
          maxVolunteers
          isCancelled
          eventScheduleId
          StartTime
          EndTime
          OwnerId
          currentVolunteerCount
          RecurrenceException
          RecurrenceID
          RecurrenceRule
        }
      }
    }
      }
      }`;

//Schedule
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      Id
      Description
      EndTime
      EndTimezone
      FollowingID
      Guid
      IsAllDay
      Location
      OwnerId
      RecurrenceException
      RecurrenceID
      RecurrenceRule
      StartTime
      StartTimezone
      maxVolunteers
      Subject
      event {
        id
        description
        name
        tags
        address1
        address2
        city
        state
        country
        zipcode
        phone
        title
        createdAt
        updatedAt
        organizationEventId
      }
      volunteerSchedule {
        nextToken
      }
      createdAt
      updatedAt
      eventScheduleId
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      Id
      Description
      EndTime
      EndTimezone
      FollowingID
      Guid
      IsAllDay
      Location
      OwnerId
      RecurrenceException
      RecurrenceID
      isCancelled
      RecurrenceRule
      StartTime
      StartTimezone
      maxVolunteers
      Subject
      event {
        id
        description
        name
        tags
        address1
        address2
        city
        state
        country
        zipcode
        phone
        title
        createdAt
        updatedAt
        organizationEventId
      }
      volunteerSchedule {
        nextToken
      }
      createdAt
      updatedAt
      eventScheduleId
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      Id
      Description
      EndTime
      EndTimezone
      FollowingID
      Guid
      IsAllDay
      Location
      OwnerId
      RecurrenceException
      RecurrenceID
      RecurrenceRule
      StartTime
      StartTimezone
      maxVolunteers
      Subject
      event {
        id
        description
        name
        tags
        address1
        address2
        city
        state
        country
        zipcode
        phone
        title
        createdAt
        updatedAt
        organizationEventId
      }
      volunteerSchedule {
        nextToken
      }
      createdAt
      updatedAt
      eventScheduleId
    }
  }
`;

export const listSchedules = /* GraphQL */  `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Id
        Description
        EndTime
        EndTimezone
        FollowingID
        Guid
        IsAllDay
        Location
        OwnerId
        RecurrenceException
        RecurrenceID
        RecurrenceRule
        StartTime
        StartTimezone
        maxVolunteers
        currentVolunteerCount
        Subject
        isCancelled
        createdAt
        updatedAt
        organizationScheduleId
        eventScheduleId
        owner
        event {
          title
          id
          organizationEventId
        }
        isCancelled
      }
      nextToken
    }
  }`;

export const scheduleByRecurrence = /* GraphQL */ `
  query ScheduleByRecurrence(
    $RecurrenceID: String
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleByRecurrence(
      RecurrenceID: $RecurrenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Id
        Description
        EndTime
        EndTimezone
        FollowingID
        Guid
        IsAllDay
        Location
        OwnerId
        RecurrenceException
        RecurrenceID
        RecurrenceRule
        StartTime
        StartTimezone
        maxVolunteers
        Subject
        createdAt
        updatedAt
        eventScheduleId
      }
      nextToken
    }
  }
`;

export const getParticipantListForEventGraphQL = `
query searchVolunteerSchedules(
  $filter: SearchableVolunteerScheduleFilterInput,
  $sort: [SearchableVolunteerScheduleSortInput],
  $limit: Int,		
  $from: Int,
  ){
    searchVolunteerSchedules(from: $from, limit: $limit,sort:$sort,filter:$filter) {
      items {
        id
        guests
        scheduleVolunteerScheduleId
        volunteerVolunteerScheduleId
        volunteer {
          address1
          address2
          city
          contactEmail
          country
          id
          image {
            key
          }
          name
          phone
          state
          tags
          zipcode
        }
      }
      total
  }
}`;

export const getVolunteerParticipantEventListGraphQL = `
  query searchVolunteerSchedules(
    $filter: SearchableVolunteerScheduleFilterInput,
		$sort: [SearchableVolunteerScheduleSortInput],
		$limit: Int,		
		$from: Int,		
    ){
      searchVolunteerSchedules(from: $from, limit: $limit,sort:$sort,filter:$filter) {
        items {
          id
          guests
          scheduleVolunteerScheduleId
          volunteerVolunteerScheduleId
          schedule {
            EndTime
            Id
            OwnerId
            RecurrenceException
            RecurrenceID
            RecurrenceRule
            StartTime
            currentVolunteerCount
            eventScheduleId
            isCancelled
            id
            maxVolunteers
            event {
              address1
              address2
              city
              country
              createdAt
              description
              id
              location {
                lat
                lon
              }
              name
              phone
              state
              tags
              title
              updatedAt
              zipcode
              organization {
                address1
                address2
                category
                city
                country
                createdAt
                description
                donations
                email
                facebook
                id
                instagram
                linkedin
                mission
                name
                phone
                pinterest
                state
                twitter
                updatedAt
                website
                youtube
                zip
                stripeAccount
              }
              organizationEventId
            }
          }
        }
        total      
    }
}`;

export const getEventsForHomePageGraphQL = `
  query searchSchedules(
    $filter: SearchableScheduleFilterInput,
		$sort: [SearchableScheduleSortInput],
		$limit: Int,		
		$from: Int,		
    ){
      searchSchedules(from: $from, limit: $limit,sort:$sort,filter:$filter) {
        items {
          EndTime
          Id
          OwnerId
          RecurrenceException
          RecurrenceID
          RecurrenceRule
          StartTime
          currentVolunteerCount
          isCancelled
          maxVolunteers
          id
          eventScheduleId
          event {
            address1
            address2
            city
            country
            createdAt
            description
            id
            image {              
              key              
            }
            images {
              key
            }
            location {
              lat
              lon
            }
            organization {
              id
              name
            }
            organizationEventId
            phone
            state
            tags
            title
            zipcode
          }
        }
        total      
    }
}`;

export const getCurrentVolunteerCountGraphQL = `
  query searchVolunteerSchedules(
    $filter: SearchableVolunteerScheduleFilterInput,
		$sort: [SearchableVolunteerScheduleSortInput],
		$limit: Int,		
		$from: Int,		
    ){
      searchVolunteerSchedules(from: $from, limit: $limit,sort:$sort,filter:$filter) {
        items {
          guests
          
        }
        total      
    }
}`;

export const getVolunteerForSchedules = `
query getSchedule(
  $id: ID!
  ){
    getSchedule(id:$id) {
      id
      eventScheduleId
      volunteerSchedule {
      items {
        id
        volunteer {
          contactEmail
          id
          name
        }
      }
    }
  }
}`;

export const createVolunteerScheduleGraphQL = `
mutation createVolunteerSchedule($input: CreateVolunteerScheduleInput!){
  createVolunteerSchedule(input:$input) {    
      guests
      id
      scheduleVolunteerScheduleId
      volunteerVolunteerScheduleId
    }
  }`;

export const deleteVolunteerScheduleGraphQL = `
mutation deleteVolunteerSchedule($input: DeleteVolunteerScheduleInput!){
  deleteVolunteerSchedule(input:$input) {    
      guests
      id
      scheduleVolunteerScheduleId
      volunteerVolunteerScheduleId
    }
  }`;

export const createDonationGraphQL = `
mutation createDonation($input: CreateDonationInput!){
  createDonation(input:$input) {    
    amount
    id
    organizationDonationId
    paymentMethod
    status
    time
    volunteerDonationId
    volunteerDonationId
    searchOrganization
    searchVolunteer
    trasactionId
    givelocalFee
    netAmount
    stripeFee
  }
}`;

export const updateDonationGraphQL = `
mutation updateDonation($input: UpdateDonationInput!){
  updateDonation(input:$input) {    
    amount
    id
    organizationDonationId
    paymentMethod
    status
    time
    volunteerDonationId
    searchOrganization
    searchVolunteer
    trasactionId
    givelocalFee
    netAmount
    stripeFee
  }
}`;

export const listDonationGraphQL = `
  query searchDonations(
    $filter: SearchableDonationFilterInput,
		$sort: [SearchableDonationSortInput],
		$limit: Int,		
		$from: Int,		
    ){
      searchDonations(from: $from, limit: $limit,sort:$sort,filter:$filter) {
        items {
          amount
          id
          organization {
            id
            name
          }
          organizationDonationId
          paymentMethod
          searchOrganization
          searchVolunteer
          status
          time
          trasactionId
          volunteer {
            id
            name
          }
          volunteerDonationId
          givelocalFee
          netAmount
          stripeFee         
        }
        total      
      }
  }`;
