export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      category
      email
      name
      owner
      mission
      stripeAccount
      description
      website
      country
      email
      address1
      address2
      city
      state
      zip
      phone
      facebook
      instagram
      twitter
      linkedin
      donations
      image {
        bucket
        key
        region
      }
      location {
        lat
        lon
      }
    }
  }
`;
