import { SET_DONATION_LIST_AND_TOTAL } from "redux/Types/donation.types";
import { SET_DONATION_LOADING } from "redux/Types/donation.types";

export const setDonationLoading = (value) => {
  return {
    type: SET_DONATION_LOADING,
    value,
  };
};

export const setDonationListAndTotal = (data, total) => {
  return {
    type: SET_DONATION_LIST_AND_TOTAL,
    data,
    total,
  };
};
