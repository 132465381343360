import { SET_CHECKOUT_PROFILE_DETAILS } from "redux/Types/checkout-types";
import { SET_CHECKOUT_LOADING } from "redux/Types/checkout-types";

export const setCheckoutLoading = (value) => {
  return {
    type: SET_CHECKOUT_LOADING,
    value,
  };
};

export const setCheckoutProfileDetails = (details) => {
  return {
    type: SET_CHECKOUT_PROFILE_DETAILS,
    details,
  };
};
