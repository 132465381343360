import { SETORGANIZATION } from "redux/Types/auth.types";
import {
  SETORGANIZATIONLISTANDTOTAL,
  SETORGANIZATIONLOADING,
  SETORGANIZATIONDETAILS,
  SETOWNERORGANIZATIONLISTANDTOTAL,
  SETOWNERORGANIZATIONDETAILS,
  SETPROFILEDETAILS,
  SETMAINLOADING,
  SETCITYLOADING,
  SETCITYDROPDOWNDATA,
  SETLOCATIONLOADING,
  SETAUTOLOCATIONDATA,
  SETCATEGORYLOADING,
  SETCATEGORYDROPDOWNDATA,
} from "redux/Types/organization.types";

const INITIAL_STATE = {
  organization: {
    orgStatement: "",
    address: "",
    orgCategory: "",
    phone: "",
    ein: "",
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    category: "",
  },
  mainLoading: false,
  loading: false,
  organizationList: [],
  organizationListTotal: 0,
  organizationDetails: {},

  ownerOrganizationList: [],
  ownerOrganizationListTotal: 0,
  ownerOrganizationDetails: {},
  apiCallChecked: false,
  profileDetails: {},

  cityLoading: false,
  cityDropdownData: [],

  locationLoading: false,
  autoLocationData: [],

  categoryLoading: false,
  categoryData: [],
};

/*eslint-disable*/
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case SETLOCATIONLOADING: {
      return {
        ...state,
        locationLoading: action.value,
      };
    }
    case SETAUTOLOCATIONDATA: {
      return {
        ...state,
        autoLocationData: action.data,
      };
    }
    case SETCITYLOADING: {
      return {
        ...state,
        cityLoading: action.value,
      };
    }
    case SETCITYDROPDOWNDATA: {
      return {
        ...state,
        cityDropdownData: action.data,
      };
    }
    case SETMAINLOADING: {
      return {
        ...state,
        mainLoading: action.value,
      };
    }
    case SETORGANIZATIONLISTANDTOTAL:
      return {
        ...state,
        organizationList: action.list,
        organizationListTotal: action.total,
      };

    case SETORGANIZATIONLOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SETORGANIZATIONDETAILS:
      return {
        ...state,
        organizationDetails: action.details,
      };
    case SETOWNERORGANIZATIONLISTANDTOTAL:
      return {
        ...state,
        ownerOrganizationList: action.list,
        ownerOrganizationListTotal: action.total,
      };
    case SETOWNERORGANIZATIONDETAILS:
      return {
        ...state,
        ownerOrganizationDetails: action.details,
      };
    case SETPROFILEDETAILS:
      return {
        ...state,
        profileDetails: action.details,
        apiCallChecked: true,
      };
    case SETCATEGORYLOADING:
      return {
        ...state,
        categoryLoading: action.value,
      };
    case SETCATEGORYDROPDOWNDATA:
      return {
        ...state,
        categoryData: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
