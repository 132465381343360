/*eslint-disable*/
import "@sweetalert2/theme-material-ui/material-ui.css";
import { checkVolunteerProfileDetails } from "apiCall/organizationApiCall";
import { checkOrganizationByOwner } from "apiCall/organizationApiCall";
import loaderImg from "assets/img/giveLocal/charity_New.gif";
import "assets/scss/material-kit-pro-react.scss?v=1.10.0";
import Amplify, { Auth } from "aws-amplify";
import Location from "aws-sdk/clients/location";
import { checkUserLoginCondition, checkUserRole } from "helper";
import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Router } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  setLocationClient,
  setLoginAuthentication,
  setUserDetails,
} from "redux/actions/auth/auth.action";
import store from "redux/store";
import "../src/assets/scss/custom/fonts/stylesheet.css";
import "./assets/font-icons/style.css";
import awsconfig from "./aws-exports";
import history from "./route/History";
import * as serviceWorker from "./serviceWorker";

const RouteComponent = React.lazy(() => import("route/index"));
Amplify.configure(awsconfig);

function App(props) {
  const authData = useSelector((state) => state.auth);
  const organizationData = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const history = useHistory();

  const createLocationClient = async () => {
    if (authData.isAuthenticate) {
      const credentials = await Auth.currentUserCredentials();
      const locationClient = new Location({
        credentials,
        region: awsconfig.aws_appsync_region,
      });
      return locationClient;
    }
    return null;
  };

  let updateUser = async () => {
    if (
      checkUserLoginCondition() &&
      checkUserLoginCondition().Username &&
      !authData.user
    ) {
      try {
        let user = await Auth.currentAuthenticatedUser();
        store.dispatch(setUserDetails(user));
        store.dispatch(setLoginAuthentication(true));
      } catch (e) {
        store.dispatch(setUserDetails(null));
        store.dispatch(setLoginAuthentication(false));
      }
    }
  };

  const checkProfileDetails = async () => {
    if (checkUserRole(authData.user, "Owner")) {
      const historyString = await checkOrganizationByOwner(
        authData.user,
        dispatch
      );
      if (
        window.location.pathname === "/profile/edit" &&
        historyString !== "/profile/view"
      ) {
        history.push(historyString);
      } else if (
        window.location.pathname === "/profile/fill" &&
        historyString !== "/profile/fill"
      ) {
        history.push(historyString);
      } else if (window.location.pathname.startsWith("/organization")) {
        if (historyString !== "/organization") {
          history.push(historyString);
        }
      } else if (historyString !== "/profile/view") {
        history.push(historyString);
      }
    } else {
      const data = await checkVolunteerProfileDetails(
        authData.gqlClient,
        authData.user,
        dispatch
      );

      if (
        window.location.pathname === "profile/edit" &&
        data.history === "/profile/fill"
      ) {
        history.push(data.history);
      } else if (
        window.location.pathname === "/profile/fill" &&
        data.history === "/events"
      ) {
        history.push(data.history);
      } else if (data.history !== "/events") {
        history.push(data.history);
      }
    }
  };

  useEffect(async () => {
    await updateUser();
  }, []);

  useEffect(async () => {
    const data = await createLocationClient();
    store.dispatch(setLocationClient(data));
  }, [authData.isAuthenticate]);

  useEffect(async () => {
    if (authData.user && !organizationData.apiCallChecked) {
      await checkProfileDetails();
    }
  }, [authData.user]);

  return (
    <Suspense
      fallback={
        <div className="full-loader">
          <img src={loaderImg} />
        </div>
      }
    >
      <RouteComponent
        user={authData.user}
        isAuthenticate={authData.isAuthenticate}
      />
    </Suspense>
  );
}

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root"),
  serviceWorker.register()
);
