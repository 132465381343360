export const getOrganizationByID = /* GraphQL */ `
  query GetOrganization($id: ID!, $filter: ModelScheduleFilterInput) {
    getOrganization(id: $id) {
      id
      image {
        key
      }
      category
      name
      owner
      mission
      description
      website
      email
      address1
      address2
      city
      state
      zip
      phone
      facebook
      instagram
      twitter
      linkedin
      youtube
      pinterest
      stripeAccount
      donations
      event {
        items {
          id
          title
          address1
          address2
          city
          country
          description
          image {
            bucket
            key
            region
          }
          images {
            bucket
            key
            region
          }
          location {
            lat
            lon
          }
          name
          organizationEventId
          owner
          phone
          state
          tags
          zipcode
          schedule(filter: $filter, sortDirection: ASC) {
            items {
              EndTime
              Id
              OwnerId
              RecurrenceException
              RecurrenceID
              RecurrenceRule
              StartTime
              currentVolunteerCount
              eventScheduleId
              id
              isCancelled
              maxVolunteers
            }
          }
        }
      }
    }
  }
`;

export const getDonationOrganizationByID = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      image {
        key
      }
      category
      name
      owner
      mission
      description
      website
      email
      address1
      address2
      city
      state
      zip
      phone
      facebook
      instagram
      twitter
      linkedin
      youtube
      pinterest
      donations
      stripeAccount
    }
  }
`;
