/* eslint-disable */
import Swal from "sweetalert2";

export const SwalPopup = (icon, title, showConfirmation) => {
  Swal.fire({
    icon: icon,
    title: title,
    showConfirmButton: showConfirmation,
    timer: 2000,
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  });
};
