import { SETORGANIZATION } from "redux/Types/auth.types";
import { SETLOCATIONLOADING } from "redux/Types/organization.types";
import { SETCATEGORYLOADING } from "redux/Types/organization.types";
import { SETCATEGORYDROPDOWNDATA } from "redux/Types/organization.types";
import { SETAUTOLOCATIONDATA } from "redux/Types/organization.types";
import { SETCITYDROPDOWNDATA } from "redux/Types/organization.types";
import { SETCITYLOADING } from "redux/Types/organization.types";
import {
  SETORGANIZATIONLISTANDTOTAL,
  SETORGANIZATIONLOADING,
  SETORGANIZATIONDETAILS,
  SETOWNERORGANIZATIONLISTANDTOTAL,
  SETOWNERORGANIZATIONDETAILS,
  SETPROFILEDETAILS,
  SETMAINLOADING,
} from "redux/Types/organization.types";

export const setOraganizationDetails = (value) => {
  return {
    type: SETORGANIZATION,
    payload: value,
  };
};

export const setOrganizationListAndTotal = (list, total) => {
  return {
    type: SETORGANIZATIONLISTANDTOTAL,
    list,
    total,
  };
};

export const setOrganizationLoading = (value) => {
  return {
    type: SETORGANIZATIONLOADING,
    value,
  };
};

export const setOrganizationDetails = (details) => {
  return {
    type: SETORGANIZATIONDETAILS,
    details,
  };
};

export const setOwnerOrganizationListAndTotal = (list, total) => {
  return {
    type: SETOWNERORGANIZATIONLISTANDTOTAL,
    list,
    total,
  };
};

export const setOwnerOrganizationDetails = (details) => {
  return {
    type: SETOWNERORGANIZATIONDETAILS,
    details,
  };
};

export const setProfileDetails = (details) => {
  return {
    type: SETPROFILEDETAILS,
    details,
  };
};

export const setMainLoading = (value) => {
  return { type: SETMAINLOADING, value };
};

export const setCityLoading = (value) => {
  return {
    type: SETCITYLOADING,
    value,
  };
};

export const setCityDropdownData = (data) => {
  return {
    type: SETCITYDROPDOWNDATA,
    data,
  };
};

export const setLocationLoading = (value) => {
  return {
    type: SETLOCATIONLOADING,
    value,
  };
};

export const setAutoLocationData = (data) => {
  return {
    type: SETAUTOLOCATIONDATA,
    data,
  };
};

export const setCategoryLoading = (value) => {
  return {
    type: SETCATEGORYLOADING,
    value,
  };
};

export const setCategoryDropdownData = (data) => {
  return {
    type: SETCATEGORYDROPDOWNDATA,
    data,
  };
};
