export const ISAUTHENTICATE = "ISAUTHENTICATE";

export const SETORGANIZATION = "SETORGANIZATION";

export const SETUSERDETAILS = "SETUSERDETAILS";

export const SETGQLCLIENT = "SETGQLCLIENT";

export const SETVISITORGQLCLIENT = "SETVISITORGQLCLIENT";

export const SETLOCATIONCLIENT = "SETLOCATIONCLIENT";
