/* eslint-disable */
import { API, graphqlOperation, Storage } from "aws-amplify";
import awsconfig from "aws-exports";
import { SwalPopup } from "components/SwalPopup/SwalPopup";
import gql from "graphql-tag";
import {
  listDonationGraphQL,
  updateDonationGraphQL,
  createDonationGraphQL,
  getEventsForHomePageGraphQL,
  getVolunteerForSchedules,
  updateSchedule,
  createSchedule,
  getCurrentVolunteerCountGraphQL,
  createVolunteerScheduleGraphQL,
  deleteVolunteerScheduleGraphQL,
  getVolunteerParticipantEventListGraphQL,
  getParticipantListForEventGraphQL,
  checkOrganizationByOwnerGraphQL,
  checkVolunteerProfileDetailsGraphQL,
  createEmailGraphQL,
  createNewEventGraphQL,
  createVolunteerProfileGraphQL,
  editVolunteerProfileGraphQL,
  getCityDropdownDataGraphQL,
  getEventDetailsGraphQL,
  getEventListGraphQL,
  getEventTagsListGraphQL,
  getOrganizationListGraphQL,
  getOwnerOrganizationListGraphQL,
  getVolunteerTagsListGraphQL,
  updateEventGraphQL,
} from "graphql/graphQlApi";
import { adminEmail, getCategoryLabel } from "helper";
import _, { filter, values } from "lodash";
import {
  setTagsDropdownData,
  setTagsDropdownLoading,
  setVolunteerTagsDropdownData,
  setVolunteerTagsDropdownLoading,
} from "redux/actions/Common/CommonAction";
import { setVolunteerEventParicipantData } from "redux/actions/Event/EventAction";
import {
  setEventDetails,
  setEventListAndTotal,
  setEventLoading,
  setEventParicipantData,
} from "redux/actions/Event/EventAction";
import {
  setAutoLocationData,
  setCityDropdownData,
  setCityLoading,
  setLocationLoading,
  setMainLoading,
  setOrganizationDetails,
  setOrganizationListAndTotal,
  setOrganizationLoading,
  setOwnerOrganizationListAndTotal,
  setProfileDetails,
} from "redux/actions/Organization/organization.action";
import { getOrganizationByID } from "views/Profile/graphQl/getOrganizationByID";
import { updateOrganization } from "views/Profile/graphQl/updateOrganization";
import { RRule, RRuleSet } from "rrule";
import moment from "moment";
import { uuidv4 } from "helper";
import { setCancelScheduleLoading } from "redux/actions/Event/EventAction";
import { setHomePageEvent } from "redux/actions/Event/EventAction";
import { setOrganizationEvent } from "redux/actions/Event/EventAction";
import { setCheckoutLoading } from "redux/actions/Checkout/checkoutAction";
import { setCheckoutProfileDetails } from "redux/actions/Checkout/checkoutAction";
import { getDonationOrganizationByID } from "views/Profile/graphQl/getOrganizationByID";
import { setDonationListAndTotal } from "redux/actions/Donation/DonationAction";
import { setDonationLoading } from "redux/actions/Donation/DonationAction";

export const getOrganizationListing = async (
  authData,
  filterData,
  dispatch
) => {
  try {
    const from = filterData.rowPerPageValue * filterData.currentPage;
    const limit = filterData.rowPerPageValue;

    let filter = {
      owner: { exists: true },
      and: { owner: { ne: "Unclaimed" }, and: { owner: { ne: " " } } },
    };
    let sort;

    if (filterData.category && filterData.category.value) {
      filter = {
        ...filter,
        category: { matchPhrasePrefix: filterData.category.value },
      };
    }

    if (filterData.sortByAlpha.value === "Alphabetical Order") {
      sort = { field: "name", direction: "desc" };
    } else {
      sort = undefined;
    }

    let optData = [];
    let total = 0;
    let variables = { from, limit, filter, sort };

    if (filterData.nameDescSearch.trim()) {
      filter = {
        ...filter,
        name: { match: filterData.nameDescSearch.trim() },
      };
      variables = { ...variables, filter };
    }

    dispatch(setOrganizationLoading(true));
    const result = await authData.visitorGQLClient.query({
      query: gql(getOrganizationListGraphQL),
      fetchPolicy: "network-only",
      variables
    });

    if (
      result.data.searchOrganizations &&
      result.data.searchOrganizations.items &&
      result.data.searchOrganizations.items.length > 0
    ) {
      result.data.searchOrganizations.items.forEach((item) => {
        optData.push({ ...item, category: getCategoryLabel(item.category) });
      });
      total = result.data.searchOrganizations.total;
    }

    dispatch(setOrganizationListAndTotal(optData, total));
    dispatch(setOrganizationLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(setOrganizationListAndTotal([], 0));
    dispatch(setOrganizationLoading(false));
  }
};

export const getOwnerOrganizationListing = async (
  authData,
  filterData,
  dispatch
) => {
  try {
    const from = filterData.rowPerPageValue * filterData.currentPage;
    const limit = filterData.rowPerPageValue;

    let filter;
    let sort;

    if (filterData.sortByAlpha.value === "Alphabetical Order") {
      sort = { field: "name", direction: "asc" };
    } else {
      sort = undefined;
    }

    let optData = [];
    let total = 0;
    let variables = { from, limit, sort, filter };

    if (filterData.nameDescSearch.trim()) {
      filter = {
        ...filter,
        or: [
          { name: { match: filterData.nameDescSearch.trim() } },
          { id: { eq: filterData.nameDescSearch.trim() } },
        ],
      };
      variables = { ...variables, filter };
    }

    dispatch(setOrganizationLoading(true));
    const result = await authData.gqlClient.query({
      query: gql(getOwnerOrganizationListGraphQL),
      fetchPolicy: "network-only",
      variables,
    });

    if (
      result.data.searchOrganizations &&
      result.data.searchOrganizations.items &&
      result.data.searchOrganizations.items.length > 0
    ) {
      result.data.searchOrganizations.items.forEach((item) => {
        optData.push({ ...item, category: getCategoryLabel(item.category) });
      });
      total = result.data.searchOrganizations.total;
    }

    dispatch(setOwnerOrganizationListAndTotal(optData, total));
    dispatch(setOrganizationLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(setOwnerOrganizationListAndTotal([], 0));
    dispatch(setOrganizationLoading(false));
  }
};

export const getOrganizationByIDApi = async (authData, id, dispatch) => {
  dispatch(setOrganizationLoading(true));

  try {
    let optData = {};

    const startDate = new Date().toISOString();

    const endDate = moment(new Date()).add("month", 2)._d.toISOString();

    let filter = {
      StartTime: { gt: new Date(startDate) },
      and: { StartTime: { lt: new Date(endDate) } },
      isCancelled: { eq: false },
    };

    const result = await authData.visitorGQLClient.query({
      query: gql(getOrganizationByID),
      fetchPolicy: "network-only",
      variables: { id, filter },
    });

    if (result.data.getOrganization) {
      optData = {
        ...result.data.getOrganization,
        category: getCategoryLabel(result.data.getOrganization.category),
      };
    }

    if (optData.image && optData.image.key) {
      const link = await Storage.get(optData.image.key);
      optData["profileImage"] = link;
    }

    let eventArray = [];

    if (
      result.data &&
      result.data.getOrganization &&
      result.data.getOrganization.event &&
      result.data.getOrganization.event.items &&
      result.data.getOrganization.event.items.length > 0
    ) {
      result.data.getOrganization.event.items.some((outerItem, outerIndex) => {
        if (
          outerItem &&
          outerItem.schedule &&
          outerItem.schedule.items &&
          outerItem.schedule.items.length > 0
        ) {
          const sortedData = _.sortBy(outerItem.schedule.items, "StartTime");
          eventArray.push({
            ...outerItem,
            date: sortedData[0].StartTime,
            endDate: sortedData[0].EndTime,
            volunteerCount: sortedData[0].maxVolunteers
              ? `${sortedData[0].currentVolunteerCount || 0} / ${
                  sortedData[0].maxVolunteers
                }`
              : null,
            isRecurring: sortedData[0] && sortedData[0].RecurrenceRule != null,
          });
        }

        if (eventArray.length > 2) {
          return true;
        }
      });
    }

    dispatch(setOrganizationEvent(eventArray));
    dispatch(setOrganizationDetails(optData));
  } catch (e) {
    console.log(e);
    dispatch(setOrganizationEvent([]));
    dispatch(setOrganizationDetails({}));
  }
  dispatch(setOrganizationLoading(false));
};

export const checkOwnerCondition = (data) => {
  if (
    !(data.name && data.name.trim()) ||
    !(data.phone && data.phone.trim()) ||
    !(data.mission && data.mission.trim())
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkOrganizationByOwner = async (user, dispatch) => {
  try {
    dispatch(setMainLoading(true));
    let data = {};
    const result = await API.graphql(
      graphqlOperation(checkOrganizationByOwnerGraphQL, {
        owner:
          user && user.attributes && user.attributes.email
            ? user.attributes.email
            : "",
      })
    );
    if (result.data.organizationByOwner.items) {
      data = result.data.organizationByOwner.items[0]
        ? {
            ...result.data.organizationByOwner.items[0],
            category: getCategoryLabel(
              result.data.organizationByOwner.items[0].category
            ),
          }
        : {};
    }
    if (data.image && data.image.key) {
      const imageLink = await Storage.get(data.image.key);
      data["profileImage"] = imageLink;
    }

    dispatch(setProfileDetails(data));
    dispatch(setMainLoading(false));
    if (!data || !data.id) {
      return "/organization";
    } else if (checkOwnerCondition(data)) {
      return "/profile/fill";
    } else {
      return "/profile/view";
    }
  } catch (e) {
    dispatch(setProfileDetails({}));
    dispatch(setMainLoading(false));
    return "/organization";
  }
};

export const checkVolunteerProfileConditions = (data) => {
  if (
    !data ||
    !data.id ||
    !(data.name && data.name.trim()) ||
    !data.contactEmail ||
    !(data.phone && data.phone.trim())
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkVolunteerProfileDetails = async (
  gqlClient,
  user,
  dispatch
) => {
  try {
    dispatch(setMainLoading(true));
    let data = {};

    const result = await gqlClient.query({
      query: gql(checkVolunteerProfileDetailsGraphQL),
      variables: {
        id: user && user.username ? user.username : "",
      },
    });

    if (result.data && result.data.getVolunteer) {
      data = result.data.getVolunteer;
    }

    if (data.image && data.image.key) {
      data["profileImage"] = await Storage.get(data.image.key);
    }

    dispatch(setProfileDetails(data));
    dispatch(setMainLoading(false));

    if (checkVolunteerProfileConditions(data)) {
      return { history: "/profile/fill", error: null };
    } else {
      return { history: "/events", error: null };
    }
  } catch (e) {
    console.log(e);
    dispatch(setProfileDetails({}));
    dispatch(setMainLoading(false));
    return { history: "", error: e };
  }
};

export const claimOrganizationProfile = async (
  data,
  item,
  authData,
  dispatch
) => {
  try {
    dispatch(setOrganizationLoading(true));
    let optData = {};
    let input = {
      id: item.id,
      owner:
        authData.user &&
        authData.user.attributes &&
        authData.user.attributes.email
          ? authData.user.attributes.email
          : "",
    };

    let emailInput = {
      from: input.owner,
      to: adminEmail,
      subject: `Claming Ownership of ${item.name}`,
      body: `Name=${data.owner} ContactInfo=${data.phone} Messgae=${data.message}`,
    };

    if (item.phone && item.phone.trim().length === 0) {
      input["phone"] = data.phone;
    }

    const result = await authData.gqlClient.mutate({
      mutation: gql(updateOrganization),
      variables: { input },
    });

    if (result.data.updateOrganization) {
      optData = result.data.updateOrganization;
    }

    if (optData.image && optData.image.key) {
      const imageLink = await Storage.get(optData.image.key);
      optData["profileImage"] = imageLink;
    }

    await authData.gqlClient.mutate({
      mutation: gql(createEmailGraphQL),
      variables: { input: emailInput },
    });

    SwalPopup("success", "Email sent successfully", false);

    dispatch(setProfileDetails(optData));
    dispatch(setOrganizationLoading(false));
    return {
      history: checkOwnerCondition(optData) ? "/profile/fill" : "/profile/view",
      error: null,
    };
  } catch (e) {
    console.log(e);
    dispatch(setProfileDetails({}));
    dispatch(setOrganizationLoading(false));
    return { history: "", error: e };
  }
};

export const abandonOrganizationProfile = async (
  id,
  name,
  authData,
  dispatch
) => {
  try {
    dispatch(setOrganizationLoading(true));
    let input = {
      id: id,
      owner: null,
    };

    let emailInput = {
      from:
        authData.user &&
        authData.user.attributes &&
        authData.user.attributes.email
          ? authData.user.attributes.email
          : "",
      to: adminEmail,
      subject: `Abandon Ownership of ${name}`,
      body:
        authData.user &&
        authData.user.attributes &&
        authData.user.attributes.email
          ? `${authData.user.attributes.email} has abandon the ownership of ${name} organization`
          : "",
    };

    const result = await authData.gqlClient.mutate({
      mutation: gql(updateOrganization),
      variables: { input },
    });

    const sendMailData = await authData.gqlClient.mutate({
      mutation: gql(createEmailGraphQL),
      variables: { input: emailInput },
    });

    SwalPopup("success", "Email sent successfully", false);

    dispatch(setProfileDetails({}));
    dispatch(setOrganizationLoading(false));
    return { history: "/organization", error: null };
  } catch (e) {
    dispatch(setProfileDetails({}));
    dispatch(setOrganizationLoading(false));
    return { history: "", error: e };
  }
};

export const organizationProfileApi = async (data, authData, dispatch) => {
  try {
    dispatch(setOrganizationLoading(true));
    let optData = {};
    let input = {
      image: data.orgImage && data.orgImage.key ? data.orgImage : null,
      mission: data.mission ? data.mission : null,
      address1: data.address1 ? data.address1 : null,
      address2: data.address2 ? data.address2 : null,
      zip: data.zip ? data.zip : null,
      city: data.city ? data.city : null,
      state: data.state ? data.state : null,
      country: data.country  ? data.country : null,
      phone: data.phone ? data.phone : null,
      id: data.ein ? data.ein : null,
      website: data.website ? data.website : null,
      donations: data.donations ? data.donations : null,
      facebook: data.facebook ? data.facebook : null,
      instagram: data.instagram ? data.instagram : null,
      twitter: data.twitter ? data.twitter : null,
      linkedin: data.linkedin ? data.linkedin : null,
    };

    const result = await authData.gqlClient.mutate({
      mutation: gql(updateOrganization),
      variables: { input },
    });

    if (result.data.updateOrganization) {
      optData = result.data.updateOrganization;
    }

    if (optData.image && optData.image.key) {
      const imageLink = await Storage.get(optData.image.key);
      optData["profileImage"] = imageLink;
    }

    dispatch(setProfileDetails(optData));
    dispatch(setOrganizationLoading(false));
    return { history: "/profile/view", error: null };
  } catch (e) {
    dispatch(setProfileDetails({}));
    dispatch(setOrganizationLoading(false));
    return { history: "", error: e };
  }
};

export const fillVolunteerProfile = async (data, authData, dispatch) => {
  try {
    dispatch(setOrganizationLoading(true));
    let optData = {};
    let tagsInput;

    if (data.category && data.category.length > 0) {
      tagsInput = _.map(data.category, "value");
    }

    let input = {
      id: data.id ? data.id : null,
      image: data.image && data.image.key ? data.image : null,
      tags: tagsInput || null,
      name: data.name,
      address1: data.address1,
      address2: data.address2 ? data.address2 : null,
      city: data.city ? data.city : null,
      state: data.state ? data.state : null,
      country: data.country ? data.country : null,
      zipcode: data.zip ? data.zip : null,
      phone: data.phone ? data.phone : null,
      contactEmail: data.email ? data.email : null,
    };

    const result = await authData.gqlClient.mutate({
      mutation: gql(createVolunteerProfileGraphQL),
      variables: { input },
    });

    if (result.data.createVolunteer) {
      optData = result.data.createVolunteer;
    }

    if (optData.image && optData.image.key) {
      optData["profileImage"] = await Storage.get(optData.image.key);
    }

    dispatch(setProfileDetails(optData));
    dispatch(setOrganizationLoading(false));
    return { history: "/events", error: null };
  } catch (e) {
    console.log(e);
    dispatch(setProfileDetails({}));
    dispatch(setOrganizationLoading(false));
    return { history: "", error: e };
  }
};

export const editVolunteerProfile = async (data, authData, dispatch) => {
  try {
    dispatch(setOrganizationLoading(true));
    let optData = {};

    let tagsInput;

    if (data.category && data.category.length > 0) {
      tagsInput = _.map(data.category, "value");
    }

    let input = {
      id: data.id ? data.id : null,
      image: data.image && data.image.key ? data.image : null,
      name: data.name,
      tags: tagsInput || null,
      address1: data.address1 ? data.address1 : null,
      address2: data.address2 ? data.address2 : null,
      city: data.city ? data.city : null,
      state: data.state ? data.state : null,
      country: data.country ? data.country : null,
      zipcode: data.zip ? data.zip : null,
      phone: data.phone ? data.phone : null,
      contactEmail: data.email ? data.email : null,
    };

    const result = await authData.gqlClient.mutate({
      mutation: gql(editVolunteerProfileGraphQL),
      variables: { input },
    });

    if (result.data.updateVolunteer) {
      optData = result.data.updateVolunteer;
    }

    if (optData.image && optData.image.key) {
      optData["profileImage"] = await Storage.get(optData.image.key);
    }

    dispatch(setProfileDetails(optData));
    dispatch(setOrganizationLoading(false));
    return { history: "/events", error: null };
  } catch (e) {
    console.log(e);
    dispatch(setProfileDetails({}));
    dispatch(setOrganizationLoading(false));
    return { history: "", error: e };
  }
};

export const getCityDropdownData = async (
  country,
  state,
  city,
  authData,
  dispatch
) => {
  try {
    let filter;
    if (country && country.value) {
      filter = { ...filter, country: { match: country.value } };
    }
    if (state && state.value) {
      filter = { ...filter, state: { match: state.value } };
    }
    if (city) {
      filter = { ...filter, city: { match: city } };
    }

    let data = [];

    dispatch(setCityLoading(true));

    const result = await authData.visitorGQLClient.query({
      query: gql(getCityDropdownDataGraphQL),
      variables: { filter, from: 0, limit: 10 },
    });

    if (
      result.data &&
      result.data.searchLocationData &&
      result.data.searchLocationData.items &&
      result.data.searchLocationData.items.length > 0
    ) {
      data = _.uniqBy(result.data.searchLocationData.items, "city");
    }

    dispatch(setCityDropdownData(data));
    dispatch(setCityLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(setCityDropdownData([]));
    dispatch(setCityLoading(false));
  }
};

export const getLocationDataSuggestions = (authData, address, dispatch) => {
  try {
    console.log("inside getlocatinodata suggestions")

    dispatch(setLocationLoading(true));
    authData.locationClient.searchPlaceIndexForSuggestions(
      {
        BiasPosition: [-111.8910, 40.7608],
        IndexName: "gl-location-search",
        MaxResults: 10,
        Text: address,
        FilterCountries: ["USA"],
      },
      (err, data) => {
        let optData = [];
        console.log("LOCATION: ")
        console.log(data)
        if (data && data.Results) {
          optData = data.Results;
        }
        if (err) {
          optData = [];
        }
        dispatch(setAutoLocationData(optData));
        dispatch(setLocationLoading(false));
      }
    );
  } catch (e) {
    console.log(e);
    dispatch(setAutoLocationData([]));
    dispatch(setLocationLoading(false));
  }
};

export const getLocationData = (authData, address, dispatch) => {
  let optData = []
  try {
    console.log("inside getlocatinodata")
    return authData.locationClient.searchPlaceIndexForText(
      {
        IndexName: "gl-location-search",
        MaxResults: 1,
        Text: address,
        FilterBBox: [-114.052962, 36.997968, -109.041058, 42.001567],
        FilterCountries: ["USA"],
      },
      (err, data) => {
        if (data && data.Results) {
          optData = data.Results;
        }
        if (err) {
          optData = [];
        }
        console.log("Returning optData1")
        console.log(optData)
        dispatch(setAutoLocationData(optData));
      }

    );
    console.log("Returning optData2");
    console.log(optData)

  } catch (e) {
    console.log(e);
    dispatch(setAutoLocationData([]));
  }
};

export const getEventListAPI = async (filterData, id, authData, dispatch) => {
  console.log("getEventListAPI")
  try {
    let filter;
    let sort;
    let distance;

    if (id) {
      filter = { ...filter, organizationEventId: { eq: id } };
    }

    if (filterData.distanceDiff) {
      distance = {
        radius: filterData.distanceDiff,
        location: { lat: 39.321, lon: 111.0937 },
      };
    }

    if (filterData.sortFieldAndOrder && filterData.sortFieldAndOrder.field) {
      sort = { ...filterData.sortFieldAndOrder };
    }

    const from = filterData.rowPerPageValue * filterData.currentPage;
    const limit = filterData.rowPerPageValue;

    if (filterData.title) {
      filter = { ...filter, title: { match: filterData.title.trim() } };
    }

    if (filterData.zipcode) {
      filter = { ...filter, zipcode: { match: filterData.zipcode.trim() } };
    }

    if (filterData.tags && filterData.tags.length > 0) {
      filter = { ...filter, tags: _.map(filterData.tags, "value") };
    }

    if (filterData.city ) {
      filter = { ...filter, city: { match: filterData.city } };
    }

    let variables = { from, limit, filter, sort, distance };

    dispatch(setEventLoading(true));

    console.log("getEventListGraphQL")
    console.log(getEventListGraphQL)
    console.log("variables")
    console.log(variables)
    const result = await authData.visitorGQLClient.query({
      query: gql(getEventListGraphQL),
      fetchPolicy: "network-only",
      variables,
    });

    let optData = [];
    let total = 0;
    console.log("result")
    console.log(result)
    if (
      result.data &&
      result.data.searchEventsByLocation &&
      result.data.searchEventsByLocation.items &&
      result.data.searchEventsByLocation.items.length > 0
    ) {
      optData = result.data.searchEventsByLocation.items;
      total = result.data.searchEventsByLocation.total;
    }

    dispatch(setEventListAndTotal(optData, total));
    dispatch(setEventLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(setEventListAndTotal([], 0));
    dispatch(setEventLoading(false));
  }
};

const listImagesAndDelete = async (id) => {
  const imagesFiles = await Storage.list(`event/${id}/`);

  if (imagesFiles.length > 0) {
    const deletedFiles = await Promise.all(
      imagesFiles.map(async (item) => {
        return await Storage.remove(item.key);
      })
    );
  }
};

const uploadImage = async (thumbnail, id) => {
  try {
    return await Storage.put(`event/${id}/${id}-thumbnail.png`, thumbnail);
  } catch (error) {
    console.log("Error uploading file: ", error);
  }
};

const uploadEventImages = async (eventImages, id) => {
  try {
    if (eventImages && eventImages.length > 0) {
      let returnEventData = [];
      returnEventData = await Promise.all(
        eventImages.map(async (item, index) => {
          const eventImageKey = await Storage.put(
            `event/${id}/${id}-eventImage-${index}.png`,
            item
          );
          return {
            key: eventImageKey.key,
            bucket: awsconfig.aws_user_files_s3_bucket,
            region: awsconfig.aws_user_files_s3_bucket_region,
          };
        })
      );
      return returnEventData;
    }
  } catch (e) {
    console.log("Error uploading file: ", e);
  }
};

export const addScheduleToEvent = async (recurrenceString, id, orgId, data) => {
  if (recurrenceString) {
    const rule = new RRuleSet();
    rule.rrule(new RRule(recurrenceString));

    const parentId = uuidv4();

    let exceptionArr = [];

    const array = rule.all();
    array.forEach((item) => {
      exceptionArr.push(
        item.toISOString().split(".")[0].replace(/[-:]/g, "") + "Z"
      );
    });

    let parentData = {
      id: parentId,
      Id: parentId,
      EndTime: data.EndTime.toISOString(),
      IsAllDay: false,
      OwnerId: id,
      RecurrenceException: exceptionArr.join(","),
      RecurrenceRule: rule.valueOf()[1].split(":")[1],
      StartTime: data.StartTime.toISOString(),
      maxVolunteers: data.totalSlot,
      currentVolunteerCount: 0,
      isCancelled: true,
      eventScheduleId: id,
      organizationScheduleId: orgId,
    };

    await API.graphql(graphqlOperation(createSchedule, { input: parentData }));

    await Promise.all(
      array.map(async (item) => {
        const diff = moment(data.EndTime).diff(
          moment(data.StartTime),
          "minutes"
        );

        let uuid = uuidv4();
        let input = {
          id: uuid,
          Id: uuid,
          OwnerId: id,
          eventScheduleId: id,
          StartTime: item.toISOString(),
          EndTime: moment(item).add(diff, "minutes")._d.toISOString(),
          //EndTime: moment(item.toISOString()).endOf("day")._d,
          maxVolunteers: data.totalSlot,
          currentVolunteerCount: 0,
          IsAllDay: false,
          RecurrenceException:
            item.toISOString().split(".")[0].replace(/[-:]/g, "") + "Z",
          RecurrenceID: parentId,
          RecurrenceRule: rule.valueOf()[1].split(":")[1],
          isCancelled: false,
          organizationScheduleId: orgId,
        };

        await API.graphql(graphqlOperation(createSchedule, { input }));
      })
    );
  } else {
    let uuid = uuidv4();
    const input = {
      id: uuid,
      Id: uuid,
      OwnerId: id,
      eventScheduleId: id,
      StartTime: data.StartTime.toISOString(),
      EndTime: data.EndTime.toISOString(),
      maxVolunteers: data.totalSlot,
      currentVolunteerCount: 0,
      isCancelled: false,
      organizationScheduleId: orgId,
    };

    await API.graphql(graphqlOperation(createSchedule, { input }));
  }
};

export const createNewEventAPI = async (
  data,
  orgId,
  recurrenceString,
  dispatch
) => {
  try {
    let tagsInput;

    if (data.category && data.category.length > 0) {
      tagsInput = _.map(data.category, "value");
    }

    let input = {
      title: data.name ? data.name : undefined,
      tags: tagsInput || undefined,
      description: data.description ? data.description : undefined,
      address1: data.address1 ? data.address1 : undefined,
      address2: data.address2 ? data.address2 : undefined,
      zipcode: data.zip ? data.zip : undefined,
      city: data.city ? data.city : undefined,
      state: data.state ? data.states : undefined,
      country: data.country ? data.country : undefined,
      organizationEventId: orgId,
    };

    if (data && data.lat) {
      input = { ...input, location: { lat: data.lat, lon: data.lon } };
    }

    dispatch(setEventLoading(true));

    const result = await API.graphql(
      graphqlOperation(createNewEventGraphQL, { input })
    );

    if (result.data && result.data.createEvent && result.data.createEvent.id) {
      if (recurrenceString) {
        await addScheduleToEvent(
          recurrenceString,
          result.data.createEvent.id,
          orgId,
          data
        );
      } else {
        await addScheduleToEvent(null, result.data.createEvent.id, orgId, data);
      }

      await listImagesAndDelete();

      const thumbKey = await uploadImage(
        data.thumbnail,
        result.data.createEvent.id
      );

      const thumbImgKey = {
        key: thumbKey.key,
        bucket: awsconfig.aws_user_files_s3_bucket,
        region: awsconfig.aws_user_files_s3_bucket_region,
      };

      const eventImagesKey = await uploadEventImages(
        data.eventImages,
        result.data.createEvent.id
      );

      const updatedResult = await API.graphql(
        graphqlOperation(updateEventGraphQL, {
          input: {
            id: result.data.createEvent.id,
            image: thumbImgKey,
            images:
              eventImagesKey && eventImagesKey.length > 0
                ? eventImagesKey
                : null,
          },
        })
      );
    }

    dispatch(setEventLoading(false));

    return result.data.createEvent;
  } catch (e) {
    console.log(e);
    dispatch(setEventLoading(false));
  }
};

export const cancelEventAPI = async (eventData, scheduleData, dispatch) => {
  dispatch(setCancelScheduleLoading(true));
  try {
    const updatedResult = await API.graphql(
      graphqlOperation(updateSchedule, {
        input: {
          id: scheduleData.id || "",
          isCancelled: true,
        },
      })
    );

    const volunteerData = await API.graphql(
      graphqlOperation(getVolunteerForSchedules, {
        id: scheduleData.id || "I",
      })
    );

    if (
      volunteerData.data &&
      volunteerData.data.getSchedule &&
      volunteerData.data.getSchedule.volunteerSchedule &&
      volunteerData.data.getSchedule.volunteerSchedule.items &&
      volunteerData.data.getSchedule.volunteerSchedule.items.length > 0
    ) {
      const userArray = _.map(
        volunteerData.data.getSchedule.volunteerSchedule.items,
        "volunteer.contactEmail"
      );

      await Promise.all(
        userArray.map(async (item) => {
          let emailInput = {
            from: adminEmail,
            to: item,
            subject: `Event Cancel`,
            body: `${
              eventData && eventData.organization && eventData.organization.name
            } has cancelled the ${eventData && eventData.title} on ${moment(
              scheduleData.StartTime
            ).format("MM/DD/YYYY")}`,
          };

          await API.graphql(
            graphqlOperation(createEmailGraphQL, { input: emailInput })
          );
        })
      );
    }

    let data = {};

    if (
      updatedResult.data &&
      updatedResult.data.updateSchedule &&
      updatedResult.data.updateSchedule.id
    ) {
      data = updatedResult.data.updateSchedule;
    }

    dispatch(setCancelScheduleLoading(false));
    return data;
  } catch (e) {
    console.log(e);
    dispatch(setCancelScheduleLoading(false));
    return e;
  }
};

export const updateEventAPI = async (data, eventId, dispatch) => {
  try {
    dispatch(setEventLoading(true));
    if (eventId) {
      await listImagesAndDelete(eventId);

      const thumbKey = await uploadImage(data.thumbnail, eventId);

      const thumbImgKey = {
        key: thumbKey.key,
        bucket: awsconfig.aws_user_files_s3_bucket,
        region: awsconfig.aws_user_files_s3_bucket_region,
      };

      const eventImagesKey = await uploadEventImages(data.eventImages, eventId);

      let tagsInput;

      if (data.category && data.category.length > 0) {
        tagsInput = _.map(data.category, "value");
      }

      let input = {
        id: eventId,
        title: data.name ? data.name : null,
        tags: tagsInput || null,
        description: data.description ? data.description : null,
        address1: data.address1 ? data.address1 : null,
        address2: data.address2 ? data.address2 : null,
        zipcode: data.zip ? data.zip : null,
        city: data.city ? data.city : null,
        state: data.state ? data.state : null,
        country: data.country ? data.country : null,
        image: thumbImgKey,
        images:
          eventImagesKey && eventImagesKey.length > 0 ? eventImagesKey : null,
      };

      if (data && data.lat) {
        input = { ...input, location: { lat: data.lat, lon: data.lon } };
      }

      const updatedResult = await API.graphql(
        graphqlOperation(updateEventGraphQL, { input })
      );

      dispatch(setEventLoading(false));

      return updatedResult.data.updateEvent;
    } else {
      throw new Error("Invalid ID");
    }
  } catch (e) {
    console.log(e);
    dispatch(setEventLoading(false));
  }
};

export const getEventDetailsApi = async (id, orgId, dispatch) => {
  try {
    dispatch(setEventLoading(true));

    const result = await API.graphql(
      graphqlOperation(getEventDetailsGraphQL, { id })
    );

    let optData;

    if (result.data && result.data.getEvent && result.data.getEvent.id) {
      optData = result.data.getEvent;
    }

    if (orgId === optData.organizationEventId) {
      if (optData.image) {
        const thumbImage = await Storage.get(optData.image.key, {
          download: true,
          cacheControl: "no-cache",
        });

        optData["thumbnailImageBlob"] = thumbImage.Body;
      }

      if (optData.images) {
        const eventImage = await Promise.all(
          optData.images.map(async (item) => {
            const eventImages = await Storage.get(item.key, {
              download: true,
              cacheControl: "no-cache",
            });

            return eventImages.Body;
          })
        );
        optData["eventImagesBob"] = eventImage;
      }

      dispatch(setEventDetails(optData));
      dispatch(setEventLoading(false));

      return optData;
    } else {
      return { error: "Not Authorized" };
    }
  } catch (e) {
    console.log(e);
    dispatch(setEventDetails({}));
    dispatch(setEventLoading(false));
    return null;
  }
};

export const getEventDetailsPageApi = async (id, orgId, authData, dispatch) => {
  try {
    dispatch(setEventLoading(true));
    const result = await authData.visitorGQLClient.query({
      query: gql(getEventDetailsGraphQL),
      fetchPolicy: "network-only",
      variables: { id },
    });

    let optData;

    if (result.data && result.data.getEvent && result.data.getEvent.id) {
      optData = result.data.getEvent;
    }

    if (orgId && orgId !== optData.organizationEventId) {
      return { error: "Not Authorized" };
    } else {
      dispatch(setEventDetails(optData));
      dispatch(setEventLoading(false));

      return optData;
    }
  } catch (e) {
    console.log("Error", e);
    dispatch(setEventDetails({}));
    dispatch(setEventLoading(false));
    return null;
  }
};

export const getEventTagsDropdownData = async (value, authData, dispatch) => {
  try {
    let filter;

    if (value && value.trim().length > 0) {
      filter = { ...filter, value: { match: value } };
    }

    dispatch(setTagsDropdownLoading(true));

    const result = await authData.visitorGQLClient.query({
      query: gql(getEventTagsListGraphQL),
      fetchPolicy: "network-only",
      variables: { filter, from: 0, limit: 10 },
    });

    let optData = [];
    let total = 0;

    if (
      result.data &&
      result.data.searchEventTags &&
      result.data.searchEventTags.items &&
      result.data.searchEventTags.items.length > 0
    ) {
      optData = result.data.searchEventTags.items;
      total = result.data.searchEventTags.total;
    }

    dispatch(setTagsDropdownData(optData));
    dispatch(setTagsDropdownLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(setTagsDropdownData([]));
    dispatch(setTagsDropdownLoading(false));
  }
};

export const getVolunteerTagsDropdownData = async (
  value,
  authData,
  dispatch
) => {
  try {
    let filter;

    if (value && value.trim().length > 0) {
      filter = { ...filter, value: { match: value } };
    }

    dispatch(setVolunteerTagsDropdownLoading(true));

    const result = await authData.visitorGQLClient.query({
      query: gql(getVolunteerTagsListGraphQL),
      fetchPolicy: "network-only",
      variables: { filter, from: 0, limit: 10 },
    });

    let optData = [];
    let total = 0;

    if (
      result.data &&
      result.data.searchVolunteerTags &&
      result.data.searchVolunteerTags.items &&
      result.data.searchVolunteerTags.items.length > 0
    ) {
      optData = result.data.searchVolunteerTags.items;
      total = result.data.searchVolunteerTags.total;
    }

    dispatch(setVolunteerTagsDropdownData(optData));
    dispatch(setVolunteerTagsDropdownLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(setVolunteerTagsDropdownData([]));
    dispatch(setVolunteerTagsDropdownLoading(false));
  }
};

export const getParticipantListForEventAPI = async (filterData, dispatch) => {
  dispatch(setEventLoading(true));
  try {
    let filter;
    let sort;
    const from = filterData.rowPerPageValue * filterData.currentPage;
    const limit = filterData.rowPerPageValue;

    if (filterData.scheduleID) {
      filter = {
        ...filter,
        scheduleVolunteerScheduleId: { eq: filterData.scheduleID },
      };
    }

    if (filterData.sortFieldAndOrder && filterData.sortFieldAndOrder.field) {
      sort = { ...filterData.sortFieldAndOrder };
    }

    const result = await API.graphql(
      graphqlOperation(getParticipantListForEventGraphQL, {
        filter,
        from,
        limit,
        sort,
      })
    );

    let data = [];
    let total = 0;

    if (
      result.data.searchVolunteerSchedules &&
      result.data.searchVolunteerSchedules.items &&
      result.data.searchVolunteerSchedules.items.length > 0
    ) {
      data = result.data.searchVolunteerSchedules.items;
      total = result.data.searchVolunteerSchedules.total;
    }

    dispatch(setEventParicipantData(data, total));
  } catch (e) {
    dispatch(setEventParicipantData([], 0));
  }

  dispatch(setEventLoading(false));
};

export const getVolunteerParticipantEventList = async (
  filterData,
  id,
  dispatch
) => {
  dispatch(setEventLoading(true));
  try {
    let filter;
    let sort;
    const from = filterData.rowPerPageValue * filterData.currentPage;
    const limit = filterData.rowPerPageValue;

    if (id) {
      filter = { ...filter, volunteerVolunteerScheduleId: { eq: id } };
    }

    if (filterData.scheduleID) {
      filter = {
        ...filter,
        scheduleVolunteerScheduleId: { eq: filterData.scheduleID },
      };
    }

    if (filterData.sortFieldAndOrder && filterData.sortFieldAndOrder.field) {
      sort = { ...filterData.sortFieldAndOrder };
    }

    const result = await API.graphql(
      graphqlOperation(getVolunteerParticipantEventListGraphQL, {
        filter,
        from,
        limit,
        sort,
      })
    );

    let data = [];
    let total = 0;

    if (
      result.data.searchVolunteerSchedules &&
      result.data.searchVolunteerSchedules.items &&
      result.data.searchVolunteerSchedules.items.length > 0
    ) {
      data = result.data.searchVolunteerSchedules.items;
      total = result.data.searchVolunteerSchedules.total;
    }

    dispatch(setVolunteerEventParicipantData(data, total));
  } catch (e) {
    console.log(e);
    dispatch(setVolunteerEventParicipantData([], 0));
  }

  dispatch(setEventLoading(false));
};

const getCurrentVolunterrCountInSchedule = async (id) => {
  try {
    let total = 0;

    const result = await API.graphql(
      graphqlOperation(getCurrentVolunteerCountGraphQL, {
        filter: { scheduleVolunteerScheduleId: { eq: id } },
      })
    );

    if (
      result.data &&
      result.data.searchVolunteerSchedules &&
      result.data.searchVolunteerSchedules.total
    ) {
      total = result.data.searchVolunteerSchedules.total;
    }

    if (
      result.data &&
      result.data.searchVolunteerSchedules &&
      result.data.searchVolunteerSchedules.items &&
      result.data.searchVolunteerSchedules.items.length > 0
    ) {
      result.data.searchVolunteerSchedules.items.forEach((item) => {
        total += (item.guests && item.guests.length) || 0;
      });
    }

    return total;
  } catch (e) {
    return e;
  }
};

export const attendOneTimeEventAPI = async (
  data,
  guestList,
  volunteerId,
  dispatch
) => {
  try {
    const count = await getCurrentVolunterrCountInSchedule(data.id);

    if (data.maxVolunteers - (count + guestList.length + 1) > 0) {
      let input = {
        guests: guestList,
        volunteerVolunteerScheduleId: volunteerId,
        scheduleVolunteerScheduleId: data.id,
      };

      let updatedInput = {
        id: data.id,
        currentVolunteerCount: count + guestList.length + 1,
      };

      await API.graphql(
        graphqlOperation(createVolunteerScheduleGraphQL, { input })
      );

      await API.graphql(
        graphqlOperation(updateSchedule, { input: updatedInput })
      );

      return {
        data: "Success",
        error: null,
      };
    } else {
      return { error: "Slot Not Available" };
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export const attendRecurringEventAllAPI = async (
  scheduleData,
  guestList,
  volunteerId
) => {
  try {
    let slotNotAvailableArray = [];
    if (scheduleData.length > 0) {
      await Promise.all(
        scheduleData.map(async (item) => {
          const count = await getCurrentVolunterrCountInSchedule(item.id);
          if (item.maxVolunteers - (count + guestList.length + 1) > 0) {
            let input = {
              guests: guestList,
              volunteerVolunteerScheduleId: volunteerId,
              scheduleVolunteerScheduleId: item.id,
            };
            let updatedInput = {
              id: item.id,
              currentVolunteerCount: count + guestList.length + 1,
            };
            await API.graphql(
              graphqlOperation(createVolunteerScheduleGraphQL, { input })
            );
            await API.graphql(
              graphqlOperation(updateSchedule, { input: updatedInput })
            );
          } else {
            slotNotAvailableArray.push(item);
          }
        })
      );

      if (slotNotAvailableArray.length === scheduleData.length) {
        throw new Error("Slot not available");
      } else if (slotNotAvailableArray.length > 0) {
        let newArr = [];
        newArr = _.map(slotNotAvailableArray, "StartTime");

        return {
          data: null,
          error: null,
          slotNotAvailableDates: newArr,
        };
      } else {
        return {
          data: "Slot booked successfully for all dates",
          error: null,
          slotNotAvailableDates: null,
        };
      }
    } else {
      throw new Error("Please provide schedule data");
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export const attendRecurringEventSpecificAPI = async (
  scheduleData,
  volunteerId
) => {
  try {
    let slotNotAvailableArray = [];
    if (scheduleData.length > 0) {
      await Promise.all(
        scheduleData.map(async (item) => {
          if (item.isChecked) {
            const count = await getCurrentVolunterrCountInSchedule(item.id);
            if (
              item.maxVolunteers -
                (count + ((item.guestList && item.guestList.length) || 0) + 1) >
              0
            ) {
              let input = {
                guests: item.guestList || [],
                volunteerVolunteerScheduleId: volunteerId,
                scheduleVolunteerScheduleId: item.id,
              };
              let updatedInput = {
                id: item.id,
                currentVolunteerCount:
                  count + ((item.guestList && item.guestList.length) || 0) + 1,
              };
              await API.graphql(
                graphqlOperation(createVolunteerScheduleGraphQL, { input })
              );
              await API.graphql(
                graphqlOperation(updateSchedule, { input: updatedInput })
              );
            } else {
              slotNotAvailableArray.push(item);
            }
          }
        })
      );

      if (slotNotAvailableArray.length === scheduleData.length) {
        throw new Error("Slot not available");
      } else if (slotNotAvailableArray.length > 0) {
        let newArr = [];
        newArr = _.map(slotNotAvailableArray, (item) =>
          moment(item.StartTime).format("MM/DD/YYYY")
        );

        return {
          data: null,
          error: null,
          slotNotAvailableDates: newArr,
        };
      } else {
        return {
          data: "Slot booked successfully for all dates",
          error: null,
          slotNotAvailableDates: null,
        };
      }
    } else {
      throw new Error("Please provide schedule data");
    }
  } catch (e) {
    console.log(e);
    return { data: null, error: e, slotNotAvailableDates: null };
  }
};

export const withdrawEventAPI = async (data) => {
  try {
    const count = await getCurrentVolunterrCountInSchedule(
      data.scheduleVolunteerScheduleId
    );

    let input = {
      id: data.id,
    };

    let updatedInput = {
      id: data.scheduleVolunteerScheduleId,
      currentVolunteerCount: count - data.guests.length - 1,
    };

    await API.graphql(
      graphqlOperation(deleteVolunteerScheduleGraphQL, { input })
    );

    await API.graphql(
      graphqlOperation(updateSchedule, { input: updatedInput })
    );

    return {
      data: "Success",
      error: null,
    };
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export const getEventForHomePageAPI = async (authData, dispatch) => {
  dispatch(setEventLoading(true));
  try {
    const startDate = new Date().toISOString();

    const endDate = moment(new Date()).add("month", 2)._d.toISOString();

    let filter = {
      StartTime: { gt: new Date(startDate) },
      and: { StartTime: { lt: new Date(endDate) } },
      isCancelled: { eq: false },
    };

    let sort = { field: "StartTime", direction: "asc" };

    let variables = { filter, sort };

    let homeEventArray = [];

    const result = await authData.visitorGQLClient.query({
      query: gql(getEventsForHomePageGraphQL),
      fetchPolicy: "network-only",
      variables,
    });

    if (
      result.data &&
      result.data.searchSchedules &&
      result.data.searchSchedules.items &&
      result.data.searchSchedules.items.length > 0
    ) {
      result.data.searchSchedules.items.some((item) => {
        const filterIndex = _.findIndex(homeEventArray, {
          id: item.event && item.event.id,
        });

        if (filterIndex === -1) {
          homeEventArray.push({
            ...(item && item.event),
            date: item.StartTime,
            endDate: item.EndTime,
            volunteerCount: item.maxVolunteers
              ? `${item.currentVolunteerCount || 0} / ${item.maxVolunteers}`
              : null,
            isRecurring: item && item.RecurrenceRule != null,
          });
        }

        if (homeEventArray.length == 6) {
          return true;
        }
      });

      dispatch(setHomePageEvent(homeEventArray));
    }
  } catch (e) {
    console.log(e);
    dispatch(setHomePageEvent([]));
  }
  dispatch(setEventLoading(false));
};

export const getDonationOrganizationByIDApi = async (id, dispatch) => {
  dispatch(setCheckoutLoading(true));
  try {
    let optData = {};
    const result = await API.graphql(
      graphqlOperation(getDonationOrganizationByID, { id })
    );

    if (result.data.getOrganization) {
      optData = {
        ...result.data.getOrganization,
        category: getCategoryLabel(result.data.getOrganization.category),
      };
    }

    dispatch(setCheckoutProfileDetails(optData));
    dispatch(setCheckoutLoading(false));

    return optData;
  } catch (e) {
    console.log(e);

    dispatch(setCheckoutProfileDetails({}));
    dispatch(setCheckoutLoading(false));

    return null;
  }
};

export const createDonationAPI = async (data) => {
  return await API.graphql(
    graphqlOperation(createDonationGraphQL, { input: data })
  );
};

export const updateDonationAPI = async (data) => {
  return await API.graphql(
    graphqlOperation(updateDonationGraphQL, { input: data })
  );
};

export const listDonationsAPI = async (filterData, dispatch) => {
  dispatch(setDonationLoading(true));
  try {
    const from = filterData.rowPerPageValue * filterData.currentPage;
    const limit = filterData.rowPerPageValue;

    let filter;
    let sort;

    if (filterData.orgId) {
      filter = { ...filter, organizationDonationId: { eq: filterData.orgId } };
    }

    if (filterData.volunteerId) {
      filter = {
        ...filter,
        volunteerDonationId: { eq: filterData.volunteerId },
      };
    }

    if (filterData.paymentStatus && filterData.paymentStatus.value) {
      filter = {
        ...filter,
        status: { eq: filterData.paymentStatus.value },
      };
    }

    if (filterData.startDate) {
      filter = {
        ...filter,
        time: { gt: filterData.startDate },
        and: { time: { lt: filterData.endDate } },
      };
    }

    if (filterData.volunteerSearchText) {
      filter = {
        ...filter,
        or: [
          {
            searchOrganization: {
              match: filterData.volunteerSearchText.trim(),
            },
          },
          { trasactionId: { eq: filterData.volunteerSearchText.trim() } },
        ],
      };
    }

    if (filterData.organizationSearchText) {
      filter = {
        ...filter,
        or: [
          {
            searchVolunteer: {
              match: filterData.organizationSearchText.trim(),
            },
          },
          { trasactionId: { eq: filterData.organizationSearchText.trim() } },
        ],
      };
    }

    if (filterData.sortFieldAndOrder && filterData.sortFieldAndOrder.field) {
      sort = { ...filterData.sortFieldAndOrder };
    }

    const result = await API.graphql(
      graphqlOperation(listDonationGraphQL, { from, limit, filter, sort })
    );

    let optData = [];
    let total = 0;

    if (
      result.data &&
      result.data.searchDonations &&
      result.data.searchDonations.items &&
      result.data.searchDonations.items.length > 0
    ) {
      optData = result.data.searchDonations.items;
      total = result.data.searchDonations.total;
    }

    dispatch(setDonationListAndTotal(optData, total));
  } catch (e) {
    dispatch(setDonationListAndTotal([], 0));
  }
  dispatch(setDonationLoading(false));
};

export const checkAccountStatus = async (accountId) => {
  try {
    let body = { account: accountId };

    const result = await API.post("givelocaliorestapi", "/stripeAccount", {
      body,
    });

    return result;
  } catch (e) {
    if (e.response) {
      console.log(e.response);
    }
  }
};

export const createAccountLink = async (accountId, accountType) => {
  try {
    let body = {
      account: accountId,
      type: accountType,
      refresh_url: `${window.location.origin}/bank-details?refreshURL=true`,
      return_url: `${window.location.origin}/bank-details?success=true`,
    };

    return await API.post("givelocaliorestapi", "/createStripeAccountLink", {
      body,
    });
  } catch (e) {
    if (e.response) {
      console.log(e.response);
    }
  }
};

export const updateBankDetailsAPI = async (accountID, dispatch) => {
  dispatch(setMainLoading(true));
  try {
    const accountLinkResult = await createAccountLink(
      accountID,
      "account_onboarding"
    );

    if (accountLinkResult.account && accountLinkResult.account.url) {
      window.location.href = accountLinkResult.account.url;
    }
  } catch (e) {
    if (e.response) {
      console.log(e.response);
    }
  }
  dispatch(setMainLoading(false));
};

export const addBankDetailsAPI = async (data, dispatch) => {
  dispatch(setMainLoading(true));
  try {
    let body = { email: data.owner };

    const result = await API.post("givelocaliorestapi", "/createStripeAccount", {
      body,
    });

    // console.log("result.account id")
    // console.log(result.account.id)
    // console.log(data.id)
    if (result.account && result.account.id) {
      let updatedInput = { id: data.id, stripeAccount: result.account.id };

      // console.log("updating org")
      // console.log({ id: data.id, stripeAccount: result.account.id })
      const updatedOrganization = await API.graphql(
          graphqlOperation(updateOrganization, { input: updatedInput })
      );
      // console.log("updatedOrganization")
      // console.log(result)
      if (
        updatedOrganization.data &&
        updatedOrganization.data.updateOrganization &&
        updatedOrganization.data.updateOrganization.id
      ) {
        // console.log("Results are ok, update profile page")
        let newData = {
          ...data,
          ...updatedOrganization.data.updateOrganization,
        };

        dispatch(setProfileDetails(newData));
      }

      const accountLinkResult = await createAccountLink(
        result.account.id,
        "account_onboarding"
      );

      if (accountLinkResult.account && accountLinkResult.account.url) {
        window.location.href = accountLinkResult.account.url;
      }
    }
  } catch (e) {
    if (e.response) {
      console.log(e.response);
    }
  }
  dispatch(setMainLoading(false));
};
