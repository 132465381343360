/*eslint-disable */
import { categoryList } from "views/Profile/graphQl/categoryList";
import _ from "lodash";
import awsConfig from "aws-exports";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import React from "react";
const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    // eslint-disable-next-line no-invalid-this
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const adminEmail = "admin@give-local.com";

const checkUserRole = (user, role) =>
  user &&
  user.signInUserSession.accessToken.payload["cognito:groups"] &&
  user.signInUserSession.accessToken.payload["cognito:groups"].includes(role);

const checkImageTypeValid = (image) => {
  const type = image.type.split("/")[1];
  if (type === "png" || type === "jpeg" || type === "jpg") {
    return true;
  } else {
    return false;
  }
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {
    type: "image/png",
  });
};

const getCategoryLabel = (data) => {
  let label;
  if (data) {
    const result = _.filter(categoryList, function (item) {
      return item.value.toLowerCase() === data.toLowerCase().charAt(0);
    });

    if (result && result.length > 0) {
      label = result[0].label;
    }
  }
  return label;
};

const checkUserLoginCondition = () => {
  return JSON.parse(
    localStorage.getItem(
      `CognitoIdentityServiceProvider.${
        awsConfig.aws_user_pools_web_client_id
      }.${localStorage.getItem(
        `CognitoIdentityServiceProvider.${awsConfig.aws_user_pools_web_client_id}.LastAuthUser`
      )}.userData`
    )
  );
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export {
  debounce,
  checkUserRole,
  checkImageTypeValid,
  dataURItoBlob,
  getCategoryLabel,
  adminEmail,
  checkUserLoginCondition,
  uuidv4,
  useQuery,
};
