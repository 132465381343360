import {
  ISAUTHENTICATE,
  SETUSERDETAILS,
  SETGQLCLIENT,
  SETVISITORGQLCLIENT,
  SETLOCATIONCLIENT,
} from "redux/Types/auth.types";

/*eslint-disable*/
export const setLoginAuthentication = (value) => {
  return {
    type: ISAUTHENTICATE,
    payload: value,
  };
};

export const setUserDetails = (user) => {
  return { type: SETUSERDETAILS, details: user };
};

export const setGqlClient = (client) => {
  return { type: SETGQLCLIENT, client };
};

export const setVisitorGqlClient = (client) => {
  return { type: SETVISITORGQLCLIENT, client };
};

export const setLocationClient = (client) => {
  return { type: SETLOCATIONCLIENT, client };
};
