import { combineReducers } from "redux";
import AuthReducers from "redux/reducers/Auth/auth.reducers";
import OrganizationReducers from "redux/reducers/Organization/organization.Reduser";
import EventReducers from "redux/reducers/Event/EventReducer";
import CommonReducers from "redux/reducers/Common/CommonReducer";
import CheckoutReducer from "redux/reducers/Checkout/checkoutReducer";
import DonationReducer from "redux/reducers/Donation/DonationReducer";
const rootReducer = combineReducers({
  auth: AuthReducers,
  organization: OrganizationReducers,
  event: EventReducers,
  common: CommonReducers,
  checkout: CheckoutReducer,
  donation: DonationReducer,
});

export default rootReducer;
