import { SETVOLUNTEERTAGSDROPDOWNDATA } from "redux/Types/common.types";
import { SETVOLUNTEERTAGSDROPDOWNLOADING } from "redux/Types/common.types";
import {
  SETTAGSDROPDOWNLOADING,
  SETTAGSDROPDOWNDATA,
} from "redux/Types/common.types";
const INITIAL_STATE = {
  tagsLoading: false,
  tagsDropdownData: [],

  volunteerTagLoading: false,
  volunteerTagDropdownData: [],
};

/*eslint-disable*/
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETTAGSDROPDOWNLOADING:
      return {
        ...state,
        tagsLoading: action.value,
      };
    case SETTAGSDROPDOWNDATA:
      return {
        ...state,
        tagsDropdownData: action.data,
      };
    case SETVOLUNTEERTAGSDROPDOWNLOADING:
      return {
        ...state,
        volunteerTagLoading: action.value,
      };
    case SETVOLUNTEERTAGSDROPDOWNDATA:
      return {
        ...state,
        volunteerTagDropdownData: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
