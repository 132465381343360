import { SET_CHECKOUT_PROFILE_DETAILS } from "redux/Types/checkout-types";
import { SET_CHECKOUT_LOADING } from "redux/Types/checkout-types";

const INITIAL_STATE = {
  loading: false,
  profileDetails: {},
};

/*eslint-disable*/
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CHECKOUT_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_CHECKOUT_PROFILE_DETAILS:
      return {
        ...state,
        profileDetails: action.details,
      };
    default:
      return state;
  }
};

export default reducer;
